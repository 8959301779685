import * as React from 'react';
import { Field, FormikProps } from 'formik';
import Combobox from 'styleguide/components/Formik/Combobox/Combobox';
import cn from 'classnames';
import css from './HeadingWithAddressSelector.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import 'styleguide/components/ReactSelect/Select.css';

interface Props<FormikValuesType> {
  heading: string;
  addresses: Address[];
  selectedAddressId: number;
  onSelectAddress: (addressId: number) => void;
  fieldAddressId: string;
  formikProps: FormikProps<FormikValuesType>;
  selectorClassName?: string;
  size?: string;
  disabled?: boolean;
  labelClassName?: string;
  titleOnTheLeft?: boolean;
}

const HeadingWithAddressSelector = <FormikValuesType,>({ ...props }: Props<FormikValuesType>) => {
  const [addresses, setAddresses] = React.useState([]);
  const addressCopyCols: number = 12;

  const generateAddressDesc = address =>
    `${address.name}-${address.company}, ${address.address1}, ${address.city}, ${address.zipcode}`;

  React.useEffect(() => {
    const addressOptions = props.addresses.map(address => ({
      key: address.id,
      label: address.description || generateAddressDesc(address),
    }));
    setAddresses(addressOptions);
  }, [props.addresses]);

  return (
    <Grid.Container className={css.headingWithAddressSelector}>
      <Grid noPadding>
        <Grid.Row className="!mx-0">
          <Grid.Col
            className={
              props.titleOnTheLeft
                ? 'paragraph-bold-mobile mb-4 !pl-0'
                : cn({ [css.formHeadingContainerAdmin]: true })
            }
            sm={12}
            md={addressCopyCols}
          >
            <Span
              className={
                props.titleOnTheLeft
                  ? 'paragraph-bold-mobile mb-4'
                  : cn(css.formHeading, { [css.formHeadingAdmin]: true })
              }
            >
              {props.heading}
            </Span>
          </Grid.Col>
          {!!addresses.length && (
            <div className={css.selectWrapper}>
              <Field
                name={props.fieldAddressId}
                className={props.selectorClassName}
                size={props.size}
                onChange={option => {
                  const addressId = option ? option.key : 0;
                  props.onSelectAddress(addressId);
                }}
                options={addresses}
                disabled={props.disabled}
                component={Combobox}
                data-cy="AddressBookCombobox"
                // label="Select an address"
                label="Address Book"
                labelClassName={props.labelClassName}
                withClear
                inPlaceError
              />
            </div>
          )}
        </Grid.Row>
      </Grid>
    </Grid.Container>
  );
};

export default HeadingWithAddressSelector;
